const data = [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "projects",
    url: "/projects",
  },
  {
    id: 3,
    text: "about me",
    url: "/about",
  },
];

export default data;
